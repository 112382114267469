export enum ForwarderFileTreeEndpointEnum {
    CRUD = '/api/forwarder/file_tree'
};

export enum ForwarderFileTreeSearchEnum {
    EdiPath = 'edi_path_id',
    Offer = 'forwarder_offer_id',
    Job = 'forwarder_jobs_id',
    JobShort = 'forwarder_job_short_id',
    Page = 'page',
    Pagination = 'pagination'
};